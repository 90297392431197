import { Http } from "../Utils";

export const fetchGlobalVars = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/auth/globalvariables/${process.env.REACT_APP_CLIENT_ID}`
  );

export const fetchBonusList = () =>
  Http.get(`/admin/bonus/list?clientId=${process.env.REACT_APP_CLIENT_ID}`);

export const getEventDetails = (event_id) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/match/${event_id}?timeoffset=+3`
  );

export const getGatewayKeys = (gateway) =>
  Http.get(`utilities/get-gateway-keys/${gateway}`);

export const getSportMenu = (period, start = "", end = "") =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/menu?period=${period}&start=${start}&end=${end}&timeoffset=+3`
  );

export const getCategories = (period, sid) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/categories/${sid}?period=${period}&timeoffset=+3`
  );

export const getTournaments = (period, cid) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/tournaments/${cid}?period=${period}&timeoffset=+3`
  );

export const getBanners = () => Http.get(`/sports/banners?banner_type=web`);

export const getTopBets = () => Http.get(`/sports/topbets`);

export const fetchFixturesByDate = (date) =>
  Http.get(`/sports/get-fixtures-by-date?date=${date}&channel=website`);

export const fetchFixturesByDateSport = (date, sport_id) =>
  Http.get(
    `/sports/get-fixtures-by-sport-date?date=${date}&sid=${sport_id}&channel=website`
  );

export const getFixture = (eventId) =>
  Http.get(`${process.env.REACT_APP_NEW_API}sports/get-fixture/${eventId}`);

export const getMatches = (tid) => Http.get(`sports/get-matches/${tid}`);

export const getFixtures = (tid, sid, period, market = "", specifier = "") =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/retail/fixtures/${tid}?sid=${sid}&source=web&period=${period}&markets=${market}&specifier=${specifier}&timeoffset=+3`
  );

export const findBet = (data) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/bets/find-coupon`, data);

export const todaysBet = () => Http.get("/user/account/today-bets");

export const findBooking = (data) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/bets/find-bet`, data);

export const login = (params) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/auth/login?source=shop`, params);

export const sendLogout = () =>
  Http.get(`${process.env.REACT_APP_NEW_API}/auth/logout`);

export const authDetails = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/auth/details/${process.env.REACT_APP_CLIENT_ID}`
  );

export const getAgentBetList = (data, page) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/betlist?page=${page}`,
    data
  );

export const getBetList = (data, page) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/bets/history?page=${page}`, data);

export const getJackpotBetList = (data, page) =>
  Http.post(`/user/account/my-jackpot-bets?page=${page}`, data);

export const cancelTicket = (ticket) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/bets/update-bet/${process.env.REACT_APP_CLIENT_ID}`,
    {
      betId: ticket,
      status: "cancel",
      entityType: "bet",
    }
  );

export const payoutTicket = (ticket) =>
  Http.get(`user/account/betslip/${ticket}/payout`);

export const getTransactions = (data, page, limit) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/user/wallet/transactions?page=${page}&limit=${limit}`,
    data
  );

export const getBonusTransactions = (data, page) =>
  Http.post(`user/account/get-bonus-transactions?page=${page}`, data);

export const getBonuses = () => Http.get(`user/account/get-bonuses`);

export const redeemBonus = () =>
  Http.get(`user/account/redeem-bonus?source=website`);

export const getLoginHistory = (data, page) =>
  Http.post(`/user/account/login-history?page=${page}`, data);

export const getWithdrawalInfo = () => Http.get(`user/account/withdrawal-info`);

export function saveTransaction(res) {
  Http.post("utilities/save-payment-transaction", res)
    .then((res) => {})
    .catch((err) => {});
}

export const getPersonalData = () => Http.get("/user/account/personal-data");

export const changePassword = (data) =>
  Http.post("user/account/change-password", data);

export const changeUserPassword = (data) =>
  Http.post("user/account/change-user-password", data);

export const postWithdrawal = (data) =>
  Http.post("user/account/withdraw", data);

export const getMarkets = (tid, sid, market_id, date = "") =>
  Http.get(
    `sports/get-odds/${tid}?sid=${sid}&market_id=${market_id}&date=${date}`
  );

export const findFixtures = (keyword) => Http.get(`sports/search?q=${keyword}`);

export const findFixture = (event_id) =>
  Http.get(`/sports/find-fixture/${event_id}`);

export const findFixtureWithOutcomes = (event_id) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/retail/fixture/${event_id}?timeoffset=+3`
  );

export const findCode = (data) => Http.post("/sports/find-code", data);

export const addUser = (data) => Http.post("/user/account/add-user", data);

export const getUsers = (id) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/admin/retail/${process.env.REACT_APP_CLIENT_ID}/agent-users?agentId=${id}`
  );

export const sendFund = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/fund-user`,
    data
  );

export const allSalesReport = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/reports/sales`,
    data
  );

export const saleReport = ({ from, to, product_type }) =>
  Http.get(`/reports/sales?from=${from}&to=${to}&product_type=${product_type}`);

export const commissionReports = ({ from, to }) =>
  Http.get(`/reports/commission?from=${from}&to=${to}`);

export const salesReport = ({ from, to, product, id }) =>
  Http.post(
    `/user/account/sales-report?from=${from}&to=${to}&product=${product}&user_id=${id}`
  );

export const commissionReport = (id, { from, to, product }) =>
  Http.post(
    `/user/account/commission-report?from=${from}&to=${to}&product=${product}`
  );

export const processCashout = (betslip_id) =>
  Http.get(`user/account/cashout/${betslip_id}`);

export const oddsLessThan = (data) => Http.post(`/sports/odds-less-than`, data);

export const oddsLessThanFixtures = (data) =>
  Http.post(`/sports/odds-less-than/fixtures`, data);

export const processTransfer = ({ id, role }) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/deposit/transfer/${id}?role=${role}`
  );

export const validateDepositCode = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/deposit/validate`,
    data
  );

export const saveNewAgent = (data) => Http.post("/save-new-agent", data);

export const getCombos = (couponData) =>
  Http.post("/sports/get-combos", { selections: couponData.selections });

export const getSplitProps = async (couponData) => {
  const res = await Http.post("/sports/get-split-props", {
    selections: couponData.selections,
  });
  couponData.noOfCombos = res.noOfCombos;
  couponData.minOdds = res.minOdds;
  couponData.maxOdds = res.maxOdds;
  couponData.maxBonus = res.maxBonus;
  couponData.minWin = res.minWin;
  couponData.maxWin = res.maxWin;

  return couponData;
};

export const getLiveFixtures = (sid = 0) =>
  Http.get(`${process.env.REACT_APP_NEW_API}/sports/highlight/live/${sid}`);

export const getLiveFixtureData = (eventId) =>
  Http.get(`sports/live/${eventId}/en`);

export const getUpcomingLive = () => Http.get("/sports/live/upcoming");

export const getOddsChange = (data) =>
  Http.post("/sports/live/oddschanged", data);

export const getJackpots = () => Http.get("/sports/jackpots");

export const sendDeposit = (data) =>
  Http.post("/user/account/send-deposit", data);

export const sendWithdrawal = (data) =>
  Http.post("/user/account/send-withdrawal", data);

export const getTipsters = () => Http.get("/sports/tipsters/all");

export const getTipsterBetslips = (user_id) =>
  Http.get(`/sports/tipsters/get-betslips/${user_id}`);

export const saveTipsterBet = (data) => Http.post("/sports/tipsters/add", data);

export const rebetTipster = (data) => Http.post("/sports/tipsters/rebet", data);

export const getPoolTickets = (data, page) =>
  Http.post(`/user/account/pool-tickets?page=${page}`, data);

export const getCouponTickets = (data, page) =>
  Http.post(`/user/account/coupon-tickets?page=${page}`, data);

export const getExpenses = () => Http.get(`/list/expense`);
export const getExpense = (id) => Http.get(`/show/expense/${id}`);

export const getExpensesType = () => Http.get(`/list/expense/type`);

export const postExpense = (data) => Http.post(`/create/expense`, data);

export const deleteExpense = (id, data) =>
  Http.delete(`/delete/expense/${id}`, data);

export const putExpense = (id, data) => Http.put(`/update/expense/${id}`, data);

export const getCashOuts = () => Http.get(`/admin/list/cashout`);

export const getCashIn = () => Http.get(`/admin/list/cashin`);

export const shopWithdrawal = ({ id, role }) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/withdraw/transfer/${id}?role=${role}`
  );

export const verifyRequest = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/withdraw/validate`,
    data
  );

export const shopAddUser = (payload) =>
  Http.post(`/user/account/retail/add-user`, payload);

export const getShopUsers = () => Http.get(`/user/account/retail/users`);

export const getElbetUsers = (user) =>
  Http.get(`/admin/agent-management/elbet-cashier?agent_id=${user}`);

export const generate = (data) => Http.post("/shop/cashbook", data);
export const cashbooktoday = (data) => Http.post("/shop/cashbook/today", data);

export const generateLatest = (data) =>
  Http.post("/shop/cashbook/latest", data);
export const getAllLivescore = () => Http.get(`/sportscore/today-event`);

export const getLivescore = () => Http.get(`/sportscore/sportscore-live`);

export const getSettledCommision = ({ from, to, type }) =>
  Http.get(`commissions/settled?from=${from}&to=${to}&type=${type}`);

export const getBalanceOverview = () =>
  Http.get("/user/account/balance-overview");

export const getLast10Transactions = () =>
  Http.get("/user/account/balance-overview");

export const getMonthBonus = () =>
  Http.get("commissions/settled-bonus?month=9&year=2023");
export const getMonthlyBonus = ({ from, to }) =>
  Http.get(`commissions/settled-bonus?from=${from}&to=${to}`);
export const getBranchSummary = () => Http.get("shop/cashbook/summary");
export const getTellerSummary = () => Http.get("shop/cashbook/teller/summary");

export const getCashiers = () => Http.get("admin/list/agents");

export const initializeTransaction = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/user/wallet/initiate-deposit?source=shop`,
    data
  );

export const verifyTransaction = (data) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/verify-payment?clientId=${process.env.REACT_APP_CLIENT_ID}&paymentChannel=${data.paymentChannel}&transactionRef=${data.trxRef}`
  );

export const getAllBanks = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/${process.env.REACT_APP_CLIENT_ID}/banks`
  );

export const getBankAccounts = () =>
  Http.get(`${process.env.REACT_APP_NEW_API}/user/wallet/bank-accounts`);

export const bankWithdrawal = (payload) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/user/wallet/withdraw`, payload);

export const verifyBankAccount = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/user/wallet/verify-bank-account`,
    data
  );
